import React, { useEffect, useState } from "react";
import useContentful from '../../../utils/useContentful'
import styled from "styled-components";
import MainLayout from '../../../components/Layouts/MainLayout'
import Herocomp from "../../../components/Publicaciones/herocomp";
import BoldContentful from "../../../utils/BoldContentful";


const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
`;

const Articulopage = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 80%;
  justify-content:space-between ;

  @media (max-width: 750px) {
    flex-direction: column-reverse;
  }
`;


const Autorpage = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  color: #c4c4c4;
  padding: 20px;
  margin-top: 0px;
  img {
    padding: 10px;
    width: 100%;
  }

  h2 {
    font-weight: 600;
    font-size:20px ;
  }
  @media (max-width: 750px) {
    width: 100%;
    align-items: center;
    margin-top: 30px;
    img {
      width: 50%;
    }
  }
`;

const Textohighlight = styled.div`
  text-align: center;
  max-width: 1440px;
  padding: 0% 5%;
  color: ${(p) => p.colorinput};
  font-size: 24px;
  font-weight: 700;
  margin-top: 40px;
  p{
     font-weight: 700;
    font-size: 24px;
    span{
        font-weight: 700;
    font-size: 24px;
    }
  }
`;

const Contentpage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const Heroimg = styled.img`
 width: 100%;
 height:400px;
 object-fit:cover ;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const ArticuloEntryId = "1cz49tBW52Ik47wuHuBA4i";


const Articulo = (props) => {
const [datapage, setDatapage] = useState([]);

let id_ = decodeURIComponent(props.params.id);

console.log("ID", id_ , props.params)


  const { data: articulo, fetched } = useContentful({ id: ArticuloEntryId});

 


  async function obtaindata() {
    if (articulo != null) {
        let indpage = await articulo.fields.articulos.filter(
          (ind) =>
            ind.fields.cardTitle == id_
        );
       
    
        setDatapage(indpage);
     
      }
  }

  useEffect(() => {
    obtaindata();
  }, [articulo]);

  const adaptContent = (pr) => {
  
    if (pr.sys.contentType.sys.id == "publicacionesArticuloParagraph") {
      return (
       BoldContentful(pr.fields.paragraph, "#424743")
      );
    }

    if (pr.sys.contentType.sys.id == "publicacionesArticuloImage") {
   
      return (
          <img style={{width: "60%" , margin: "0px 0px 40px 0px"}} src={pr.fields.image.fields.file.url} alt={pr.fields.bannerAlt} />
       
      );
    }

    if (pr.sys.contentType.sys.id == "publicacionesArticuloTitle") {
      let choseencolor = pr.fields.colors;

      const colorpalete = [
        {
          name: "Negro y magenta",
          first: "#000000",
          second: "#EA5C90",
        },
        {
          name: "Negro y verde",
          first: "#000000",
          second: "#008991",
        },
        {
          name: "negro/verde-oscuro",
          first: "#000000",
          second: "#09868D",
        },
        {
          name: "negro/amarillo",
          first: "#000000",
          second: "#F0B330",
        },
        {
          name: "negro/azul",
          first: "#000000",
          second: "#2C5A7F",
        },
        {
          name: "negro/plomo",
          first: "#000000",
          second: "#424743",
        },
        {
          name: "negro/rojo",
          first: "#000000",
          second: "#EA5C90",
        },
        {
          name: "negro/verde-claro",
          first: "#000000",
          second: "#76C3C7",
        },
        {
          name: "negro/celeste",
          first: "#000000",
          second: "#53CAEC",
        },
        {
          name: "negro/morado",
          first: "#000000",
          second: "#9371B0",
        },
     
      ];

     
      
      let arraycolor = colorpalete.filter((el) => el.name === choseencolor);
      
     
      return (
        <Textohighlight colorinput={arraycolor[0].first}>
          {BoldContentful(pr.fields.title, arraycolor[0].second)}
        </Textohighlight>
      );
    }

  
  };


  const articlePage = (props) => {
   
    return (
        <MainLayout active={5}>
       
        <Container>
          <Heroimg
            src={props[0].fields.heroImage.fields.file.url}
          ></Heroimg>
          <Articulopage>
            <Autorpage>
            {props[0].fields.imageAuthor.fields.file.url && <img src={props[0].fields.imageAuthor.fields.file.url} />}
            {props[0].fields.author &&  <h2>{props[0].fields.author}</h2>}
             
            </Autorpage>
            <Contentpage>
              {props[0].fields.content.map((ind) => adaptContent(ind))}
            </Contentpage>
          </Articulopage>

          <br></br>
          <br></br>
          <br></br>
        </Container>
      </MainLayout>
    );
  };



  return(
    fetched && datapage.length ? 
    articlePage(datapage)
    :
    <div
     style={{
       width: "100vw",
       height: "100vh",
       display: "flex",
       justifyContent: "center",
       marginTop: "0px",
       padding: "15%",
     }}
   >
     {" "}
     <svg
       version="1.1"
       id="L9"
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 100 100"
       enableBlackground="new 0 0 0 0"
       xmlSpace="preserve"
     >
       <path
         fill="#008991"
         d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
       >
         <animateTransform
           attributeName="transform"
           attributeType="XML"
           type="rotate"
           dur="1s"
           from="0 50 50"
           to="360 50 50"
           repeatCount="indefinite"
         />
       </path>
     </svg>
   </div>
 
  )
} 

export default Articulo;